'use client';

import { useEffect } from 'react';
import Button, { Color } from '~/core/ui/Button';

import * as Sentry from '@sentry/nextjs';
import configuration from '~/configuration';
import { getBrandSpecificIcons } from '@portal/branding/icons';
import { useGetComponentConfig } from '@portal/branding/hooks/useGetComponentConfig';
import { Trans } from 'react-i18next';

export const metadata = {
  title: `${process.env.NEXT_PUBLIC_ASSISTANT_NAME} is very busy - ${configuration.site.name}`,
};

const { ChatBot } = getBrandSpecificIcons();

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
  const { data: brandConfig } = useGetComponentConfig<{ color?: Color }>('components.error-page-button');

  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);
  return (
    <main>
      <div className="m-auto flex min-h-screen w-full h-full items-center justify-center bg-[length:100%_100%] bg-no-repeat bg-error-page">
        <div className={'flex flex-col space-y-8'}>
          <div className={'flex space-x-8'}>
            <div className={'flex flex-col pl-8'}>
              <div className={'flex flex-col items-center'}>
                <ChatBot className={'w-16 h-16'} />

                <div className="bg-gradient-to-b from-brand-error-page-title-gradient-from to-brand-error-page-title-gradient-to text-transparent bg-clip-text text-2xl font-bold pb-0.5 pt-2">
                  {process.env.NEXT_PUBLIC_ASSISTANT_NAME} is very busy right now!
                </div>

                <div className={'text-base text-black font-normal'}>
                  We are experiencing high demand at the moment. Please try again soon
                </div>
              </div>

              <div className={'flex space-x-4 justify-center mt-2.5'}>
                <Button color={'white'} href={'/settings/help'} size={'small'} className={'rounded-lg'} round={true}>
                  Contact Us
                </Button>

                {brandConfig && (
                  <Button
                    href={'/'}
                    size={'small'}
                    round={true}
                    className={'bg-gradient-to-b from-[#240647] to-[#0C061F] text-white rounded-lg'}
                    {...brandConfig.props}
                  >
                    <Trans i18nKey={'common:backToHomePage'} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
